body {
    margin: 0;
    font-family: "Noto Sans Thai", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.chart-container {
    min-height: 400px;
}

@media (max-width: 1199.98px) {
    .app-sidepanel.sidepanel-visible {
        display: none;
    }
}

.dropdown-toggle::after {
    content: unset;
}
